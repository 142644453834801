<template>
  <div>
    <a-page-header
      class="page-header"
      :ghost="false"
      style="margin-bottom: 16px"
    >
      <template #title>
        <a-space size="middle">
          <a-icon type="alert"></a-icon>
          <span>历史告警</span>
        </a-space>
      </template>
      <span>历史告警包含名称、严重性、触发时间等。</span>
    </a-page-header>

    <a-card :bordered="false" style="margin-bottom: 16px">
      <alert-table></alert-table>
    </a-card>
  </div>
</template>

<script>
import AlertTable from '@/components/table/AlertTable.vue'

export default {
  name: 'AlertHistory',
  components: {
    AlertTable
  }
}
</script>
